import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import MEDIA from 'helpers/mediaTemplates';
import Head from 'components/head';
import styled from 'styled-components';
import { format } from 'date-fns';

const IFrameWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Center = styled.main`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`;

const VideoContainer = styled.div`
  width: 50%;
  flex-grow: 1;
  padding: 2rem 4rem;
  min-width: 500px;

  @media (max-width: 500px) {
    min-width: 260px;
  }
`;

export const Title = styled.h2`
  display: block;
  font-size: 3rem;
  font-weight: 500;
  margin: 2rem 0.4rem 0.8rem;
  text-decoration: none;
`;

export const VideoDate = styled.p`
  margin: 0 0.4rem 2rem;
  max-width: 100%;
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 0.4rem 0.4rem;
  line-height: 1.4em;
  max-width: 100%;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;

const HyperShares = ({ data }) => (
  <Layout>
    <Head pageTitle={data.aboutJson.title} />
    <Center>
      {data.allYoutubeVideo.edges.map((node) => {
        const video = node.node;

        return (
          <VideoContainer key={video.videoId}>
            <IFrameWrapper>
              <iframe
                width="1280"
                height="720"
                src={`https://www.youtube.com/embed/${video.videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </IFrameWrapper>
            <Title>
              {video.title
                .replace(' – Hyper Share', '')
                .replace(' - Hyper Share', '')}
            </Title>
            <VideoDate>
              {format(new Date(video.publishedAt), 'MMMM Lo yyyy')}
            </VideoDate>
            <Copy>{video.description}</Copy>
            <Copy>
              <a
                href={`http://www.youtube.com/watch?v=${video.videoId}`}
                target="blank"
              >
                Watch on YouTube
              </a>
            </Copy>
          </VideoContainer>
        );
      })}
    </Center>
  </Layout>
);

HyperShares.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HyperShares;

export const query = graphql`
  query HyperShareQuery {
    aboutJson {
      title
    }

    allYoutubeVideo {
      edges {
        node {
          title
          videoId
          description
          publishedAt
          thumbnail {
            url
          }
        }
      }
    }
  }
`;
